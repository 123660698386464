import Vue from 'vue'
import VueRouter from 'vue-router'
import navBar from '../components/navBar.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'navBar',
        component: navBar,
        redirect: '/home',
        children: [{
                path: '/home',
                name: 'home',
                meta: { title: "首页" },
                component: () =>
                    import ('../views/home/home.vue')
            },
            {
                path: '/message',
                name: 'message',
                meta: { title: "分享" },
                component: () =>
                    import ('../views/fenxiang/message.vue')
            },
            {
                path: '/mine',
                name: 'mine',
                meta: { title: "我的" },
                component: () =>
                    import ('../views/mymine/mine.vue')
            },

        ],
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: "登录" },
        component: () =>
            import ('../components/account/login.vue')
    },
    // 用户协议
    {
        path: '/userAgreement',
        name: 'userAgreement',
        meta: { requireAuth: true, title: '用户协议' },
        component: () =>
            import ('../components/userAgreement.vue')
    },
    // 注册
    {
        path: '/zhuce',
        name: 'zhuce',
        meta: { requireAuth: true, title: '注册' },
        component: () =>
            import ('../components/account/zhuce.vue')
    },

    // 找回密码
    {
        path: '/retPassword',
        name: 'retPassword',
        meta: { requireAuth: true, title: '找回密码' },
        component: () =>
            import ('../components/account/retPassword.vue')
    },
    //  忘记密码
    {
        path: '/retrievePw',
        name: 'retrievePw',
        meta: { requireAuth: true, title: '修改密码' },
        component: () =>
            import ('../components/account/retrievePw.vue')
    },
    // 实名认证
    {
        path: '/autonymAt',
        name: 'autonymAt',
        meta: { title: "实名认证" },
        component: () =>
            import ('../views/mymine/autonymAt.vue')
    },
    {
        path: '/regardsWe',
        name: 'regardsWe',
        meta: { title: '关于我们' },
        component: () =>
            import ('../views/mymine/regardsWe.vue')
    },
    {
        path: '/operatingI',
        name: 'operatingI',
        meta: { title: '操作说明' },
        component: () =>
            import ('../views/mymine/operatingI.vue')
    },
    {
        path: '/withholdList',
        name: 'withholdList',
        meta: { title: '代扣列表' },
        component: () =>
            import ('../views/home/withholdList.vue')
    },
    {
        path: '/xieyi',
        name: 'xieyi',
        meta: { title: '协议支付' },
        component: () =>
            import ('../views/home/xieyi.vue')
    },

    {
        path: '/withholdData',
        name: 'withholdData',
        meta: { title: '单笔支付', keepAlive: true },
        component: () =>
            import ('../views/home/withholdData.vue')
    },
    {
        path: '/multiple',
        name: 'multiple',
        meta: { title: '分期支付', keepAlive: true },
        component: () =>
            import ('../views/home/multiple.vue')
    },


    {
        path: '/withholdbincj',
        name: 'withholdbincj',
        meta: { title: '绑定信息' },
        component: () =>
            import ('../views/home/withholdbincj.vue')
    },
    {
        path: '/securityCode',
        name: 'securityCode',
        meta: { title: '验证信息' },
        component: () =>
            import ('../views/home/securityCode.vue')
    },
    {
        path: '/yamCode',
        name: 'yamCode',
        meta: { title: '预览合同' },
        component: () =>
            import ('../views/home/yamCode.vue')
    },

    {
        path: '/shimingwait',
        name: 'shimingwait',
        meta: { title: '等待结果' },
        component: () =>
            import ('../views/mymine/shimingwait.vue')
    },
    {
        path: '/codewait',
        name: 'codewait',
        meta: { title: '等待结果' },
        component: () =>
            import ('../views/home/codewait.vue')
    },
    {
        path: '/contract',
        name: 'contract',
        meta: { title: '查看合同' },
        component: () =>
            import ('../views/home/contract.vue')
    },
    {
        path: '/lookdetaile',
        name: 'lookdetaile',
        meta: { title: '查看详情' },
        component: () =>
            import ('../views/home/lookdetaile.vue')
    },

    {
        path: '/deposit',
        name: 'deposit',
        meta: { title: '查询提现' },
        component: () =>
            import ('../views/home/deposit.vue')
    },
    {
        path: '/subordinate',
        name: 'subordinate',
        meta: { title: '我的下级' },
        component: () =>
            import ('../views/mymine/subordinate.vue')
    },

    {
        path: '/explain',
        name: 'explain',
        meta: { title: '推广说明' },
        component: () =>
            import ('../views/mymine/explain.vue')
    },
    {
        path: '/webview',
        name: 'webview',
        meta: { title: '推广说明' },
        component: () =>
            import ('../views/home/webview.vue')
    },


    {
        path: '*',
        meta: { title: '页面不存在' },
        hidden: true,
        component: () =>
            import ('../components/404.vue')
    },
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    // document.title = `${to.meta.title} | vue-manage-system`;
    // console.log(to.meta.requireAuth)

    if (to.meta.requireAuth != undefined || to.meta.requireAuth) {
        document.title = to.meta.title
        next();
    } else {
        document.title = to.meta.title
        if (to.path === "/login") {
            return next()
        }

        // 获取用户
        const tokens = localStorage.getItem('withhold_tokens');
        // console.log(tokens)
        if (!tokens) {
            return next("/login")
        }
        next()

    }

});
export default router