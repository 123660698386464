<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import navBar from "./components/navBar";

(function () {
  if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
  ) {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
    }
  }

  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke("setFontSizeCallback", {fontSize: 0});
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on("menu:setfont", function () {
      WeixinJSBridge.invoke("setFontSizeCallback", {fontSize: 0});
    });
  }
})();
export default {
  // components: {
  //   navBar,
  // },
  mounted() {
    this.fontsize();
  },
  methods: {
    fontsize() {
    },
  },
};
</script>
<style lang="scss">
body {
  -webkit-text-size-adjust: 100% !important;
}

#app {
  padding: 0;
  margin: 0;
}

.title_fh {
  width: 100%;
  height: 2.3rem;
  line-height: 2.3rem;
  background: #ffffff;
  border-bottom: 0.005rem solid #cccc;

  .fanhui {
    width: 1.3rem;
    margin-left: 0.2rem;
    height: 1.3rem;
    display: inline-block;
    font-weight: 550;

    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  .titel_nrons {
    display: inline-block;
    font-size: 1rem;
    font-weight: 550;
    vertical-align: middle;
    margin-left: 0.2rem;
  }
}

.backsOut {
  height: 2.5rem;
  background-color: #e0e0e0;

  .backs {
    /* position: fixed; */
    /* top: 0; */
    display: block;
    width: 95%;
    font-size: 1rem;
    font-weight: bold;
    /* padding-left: 0.4rem; */
    /* padding-top: 0.2rem; */
    /* padding-bottom: 0.2rem; */
    padding-left: 0.8rem;
    z-index: 999;

    img {
      width: 1.3rem;
      height: 1.3rem;
      vertical-align: sub;
      margin-right: 0.2rem;
    }

    span {
      line-height: 2.5rem;
      vertical-align: middle;
    }
  }
}
</style>
