<template>
  <div>
    <router-view />
    <van-tabbar @change="hittab" class="eriergf" v-model="active" route>
      <van-tabbar-item replace to="/home" icon="wap-home-o"
        >首页</van-tabbar-item
      >
      <van-tabbar-item replace to="/message" icon="share-o"
        >分享</van-tabbar-item
      >
      <van-tabbar-item replace to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data() {
    return {
      active: "0",
    };
  },
  methods: {
    hittab(e) {
      console.log(e);
      this.active = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.active_tab img {
  width: 26px;
  height: 26px;
}

.van-tabbar-item--active {
  color: #1296db;
}
.eriergf{
  position: fixed;
  z-index: 999;
}

</style>
